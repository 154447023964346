import React, { useEffect, useRef, useState } from "react";
import { REACT_APP_GOOGLE_MAPS_KEY } from '../services/helper';



const SearchLocationInput = ({ setSelectedLocation }) => {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  let autoComplete;
  const loadScript = (url, callback) => {
    const existingScript = document.getElementById("google-maps-script");
    if (!existingScript) {
      let script = document.createElement("script");
      script.id = "google-maps-script";
      script.type = "text/javascript";
      script.src = url;
      script.onload = () => callback();
      document.head.appendChild(script);
    } else {
      callback();
    }
  };

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    if (window.google && window.google.maps) {
      autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        {
          componentRestrictions: { country: "IN" },
        }
      );
      autoComplete && autoComplete?.addListener("place_changed", () => {
        handlePlaceSelect(updateQuery);
      });
    }
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };

    setSelectedLocation(latLng);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
    <div className="search-location-input w-full mt-3 ml-3 w-1/2 absolute">
      <input
        type="search"
        ref={autoCompleteRef}
        className="form-control w-full border-2 border-gray-400 focus:border-0 rounded-md px-3 py-2 focus:outline-gray-600"
        onInput={(event) => setQuery(event.target.value)}
        placeholder="Search Places ..."
        value={query}
      />
    </div>
  );
};

export default React.memo(SearchLocationInput);